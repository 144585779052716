import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { CompaniesListResponse, Company } from '../store/entities/companies/company.model';
import { DeleteCompany, AddCompanyRequest, UpdateCompany } from '../store/entities/companies/companies.actions';

@Injectable()
export class CompaniesService {

  constructor(private http: HttpClient) {}

  getCompanies(parameters: any): Observable<CompaniesListResponse> {
    return this.http.post<CompaniesListResponse>(environment.API_URL + 'customers', parameters);
  }

  updateCompany(action: UpdateCompany): Observable<Company> {
    return this.http.put<Company>(environment.API_URL + 'customers', action.payload.changes);
  }

  addCompany(action: AddCompanyRequest): Observable<Company> {
    return this.http.post<Company>(environment.API_URL + 'customers', action.payload);
  }

  removeCompany(action: DeleteCompany): Observable<any> {
    const options = {
      headers: new HttpHeaders(),
      body: {
        id: action.payload}
    };

    return this.http.delete(environment.API_URL + 'customers', options);
  }

  // importCustomer(action: ImportCompany): Observable<Company[]> {
  //   const body = {
  //     enterpriseNumber: action.payload
  //   }

  //   return this.http.post<Company[]>(environment.API_URL + 'knowncompanies', body);
  // }

  // convertCompany(action: ImportCustomerConvert): Observable<Company> {
  //   const companies = action.payload;

  //   if (companies.length > 0) {
  //     let customer: Company;
  //     customer = {
  //       ...customer,
  //       name : companies[0].names[0].name,
  //       enterpriseNumber : companies[0].enterpriseNumber,
  //       source: 'Importé',
  //       address: {
  //         street: companies[0].address.street + ', ' + companies[0].address.houseNumber,
  //         zipCode: companies[0].address.zipCode,
  //         municipality: companies[0].address.municipality,
  //         country: companies[0].address.country
  //       },
  //       createdOn: new Date()
  //     };

  //     return of(customer);
  //   }
  // }
}
