// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    'aws_project_region': 'eu-west-1',
    'aws_cognito_identity_pool_id': 'eu-west-1:447b3870-d871-459f-aef0-184c8b35aead',
    'aws_cognito_region': 'eu-west-1',
    'aws_user_pools_id': 'eu-west-1_6vDKn25IU',
    'aws_user_pools_web_client_id': '3gqtlsfvuq9nchrt07513siu9q',
    'oauth': {}
};


export default awsmobile;